<template>
  <Menu as="div" class="relative ml-3">
    <div>
      <MenuButton class="relative flex rounded-full text-sm focus:outline-none  p-1 text-white" @click="open()">
        <span class="absolute -inset-1.5" />
        <span class="sr-only">Open user menu</span>
        <AdjustmentsHorizontalIcon class="h-6 w-6" aria-hidden="true" />
      </MenuButton>
    </div>

    <MenuItems static v-show="isopen" v-if="mounted" ref="menuitems"
      class="absolute right-0 z-10 mt-4 w-20rem origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div v-for="category in categories" :key="category.slug" class="my-4">
        <div class="px-4 mb-2">
          {{ category.title }}
        </div>
        <MenuItem v-for="source in category._source" :key="source.slug">
        <SwitchGroup>
          <div class="flex gap-2 px-4">
            <Switch v-model="sourceSettings[source.slug]"
              :class='sourceSettings[source.slug] ? "bg-blue-600" : "bg-gray-200"'
              class="relative inline-flex h-5 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span :class='sourceSettings[source.slug] ? "translate-x-4" : "translate-x-0"'
                class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform" />
            </Switch>
            <SwitchLabel class="mr-4 text-gray-500" :title="source.requireLoginText">{{ source.title }}
              <LockClosedIcon v-if="source.requireLogin" class="inline require-login-icon" />
            </SwitchLabel>
          </div>
        </SwitchGroup>
        </MenuItem>
      </div>

      <div class="mt-4 p-2">
        <MenuItem>
        <button class="border-gray-300 border-2 text-gray-700 py-1 rounded w-full " @click="isopen = false">
          Close
        </button>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>


<script lang="ts" async setup>
import { Menu, MenuButton, MenuItem, MenuItems, Switch, SwitchLabel, SwitchGroup } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, LockClosedIcon } from '@heroicons/vue/24/outline'
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/solid'
import { onClickOutside } from '@vueuse/core'
import useLocalSetting from "@/composables/useLocalSetting"

interface Props {
}
const props = defineProps<Props>()

const isopen = ref(false)
const menuitems = ref(null)
onMounted(() => {
  onClickOutside(menuitems, (e) => {
    if (isopen.value) {
      setTimeout(() => {
        isopen.value = false;

      }, 50)
    }
  })
})


const {
  sources,
  categories,
} = await useData()

const hiddenSources = useLocalSetting('hiddenSources', 1, {});

const sourceSettings = reactive({});
watchEffect(() => {
  sources.value.forEach(s => {
    sourceSettings[s.slug] = computed({
      get() {
        return !hiddenSources.value[s.slug]
      },
      set(newValue) {
        hiddenSources.value[s.slug] = !newValue
      }
    });
  });
});

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
})

const open = () => {
  if (!isopen.value) {
    isopen.value = true
  }
}

const close = () => {
  isopen.value = false;
}

defineExpose({ close })
</script>

<style scoped>
.w-20rem {
  width: 20rem;
}
.require-login-icon {
  width: 1em;
  height: 1em;
  vertical-align: text-top;
}
</style>
